/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  width: 100%;
  font-family: "Space Grotesk", Verdana, sans-serif;
}
body {
  margin: 0;
}

a {
  font-size: larger;
}