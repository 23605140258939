// Import library functions for theme creation.
@use "@angular/material" as mat;
@import "@angular/material/theming";

@mixin app-color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat-get-color-config($config-or-theme);
  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);

  // Matrial icons

  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-button {
    font-size: 40px;
  }

  .material-icons.md-dark {
    color: rgba(
      $color: mat.get-color-from-palette($primary, default),
      $alpha: 1
    );
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(
      $color: mat.get-color-from-palette($primary, default),
      $alpha: 0.3
    );
  }

  .material-icons.md-light {
    color: rgba(
      $color: mat.get-color-from-palette($accent, default),
      $alpha: 1
    );
  }
  .material-icons.md-light.md-inactive {
    color: rgba(
      $color: mat.get-color-from-palette($accent, default),
      $alpha: 0.3
    );
  }

  // Media queries

  .xsmall-hide {
    @media (max-width: 599px) {
      display: none !important;
    }
  }
  .xsmall-show {
    @media (max-width: 599px) {
      display: initial !important;
    }
  }

  .small-hide {
    @media (max-width: 959px) {
      display: none !important;
    }
  }
  .small-show {
    @media (max-width: 959px) {
      display: initial !important;
    }
  }

  // Define any styles affected by the theme.

  .body {
    font-size: large;

    // Use mat-color to extract individual colors from a palette.
    background-color: mat.get-color-from-palette($accent, default);
    color: mat.get-color-from-palette($accent, default-contrast);

    @media (max-width: 599px) {
      background-image: linear-gradient(
        to bottom,
        rgba(
          $color: mat.get-color-from-palette($primary, darker),
          $alpha: 0.5
        ),
        mat.get-color-from-palette($accent, default)
      );
    }
    @media (min-width: 600px) {
      background-image: linear-gradient(
          to bottom,
          rgba(
            $color: mat.get-color-from-palette($accent, default-contrast),
            $alpha: 0.3
          ),
          mat.get-color-from-palette($accent, default)
        ),
        url(/assets/images/Emili_backgound.jpg);
    }
    background-size: cover;
    background-position-x: 90%;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .accent-contrast {
    color: mat.get-color-from-palette($accent, lighter);
    background-color: mat.get-color-from-palette($accent, default-contrast);
  }

  .accent-highlight {
    color: white;
  }

  p {
    margin-bottom: 0.1em;
  }
  
  h2 {
    @media (max-width: 600px) or (max-height: 400px) {
      margin: 0.2em;
    }
  }

  a,
  h2,
  h3,
  footer {
    color: mat.get-color-from-palette($primary, default);
  }

  a {
    text-decoration: none;
  }

  .main-sub-title {
    color: rgba(
      $color: mat.get-color-from-palette($accent, default),
      $alpha: 0.8
    );
    background-color: rgba(
      $color: mat.get-color-from-palette($primary, default),
      $alpha: 0.5
    );
  }

  .navigation .mat-icon-button {
    background-color: rgba(
      $color: mat.get-color-from-palette($accent, default),
      $alpha: 0.4
    );
    border-radius: 4px;
  }

  .navigation .mat-tab-header-pagination {
    background: inherit !important;
  }

  .navigation.mat-tab-nav-bar {
    border-bottom-color: transparent;
  }

  .mat-tab-link {
    color: inherit !important;
  }

  a.mat-tab-link {
    opacity: 1;
    font-size: 90%;

    // Default is set by color of mat-tab
    // color: mat-color($accent, default) !important;
  }

  .mat-menu-panel {
    font-weight: bolder;
    background-color: rgba(
      $color: mat.get-color-from-palette($accent, lighter),
      $alpha: 0.95
    );
  }

  .active-menu-item {
    background-color: rgba(
      $color: mat.get-color-from-palette($accent, darker),
      $alpha: 0.2
    );
  }

  .intro-underlay {
    background: linear-gradient(
      to top,
      rgba(
        $color: mat.get-color-from-palette($accent, default-contrast),
        $alpha: 0.5
      ),
      transparent
    );
  }

  .mat-snack-bar-container {
    color: mat.get-color-from-palette($accent, lighter);
    background-color: mat.get-color-from-palette($accent, lighter-contrast);
    &.error-message {
      background-color: mat.get-color-from-palette($primary, default);
    }
  }

  .floating-header {
    border-radius: 4px;
    $transp: rgba(
      $color: mat.get-color-from-palette($accent, lighter),
      $alpha: 0.2,
    );
    $bg0: rgba(
      $color: mat.get-color-from-palette($accent, default),
      $alpha: 0.6,
    );
    $bg: rgba(
      $color: mat.get-color-from-palette($accent, default),
      $alpha: 0.4,
    );
    background: linear-gradient(to bottom, $transp 10%, $bg0 60%, $bg 90%),
      linear-gradient(to left, $transp, $bg0, $transp);
  }

  .page-info-panel {
    width: 50em;
    max-width: 100%;
    margin: auto;

    padding: 1em 0;
    text-align: center;

    > h2 {
      font-family: Impact, Verdana, sans-serif;
      font-weight: 500;
      padding: 1em;
      margin: auto;
    }
    > div {
      text-align: left;
      padding: 1em 2em;
      margin: 0 1em;
      margin-bottom: 0;
    }
  }

  .widget-background {
    background-color: rgba(
      $color: mat.get-color-from-palette($accent, lighter),
      $alpha: 0.85
    );
    .mat-divider {
      border-top-color: rgba(
        $color: mat.get-color-from-palette($accent, lighter-contrast),
        $alpha: 0.3
      );
      border-right-color: rgba(
        $color: mat.get-color-from-palette($accent, lighter-contrast),
        $alpha: 0.3
      );
    }
  }

  .widget-selection {
    color: mat.get-color-from-palette($accent, default);
    background-color: rgba(
      $color: mat.get-color-from-palette($accent, lighter-contrast),
      $alpha: 0.8
    );

    .mat-ripple-element {
      background-color: rgba(
        $color: mat.get-color-from-palette($accent, default),
        $alpha: 0.2
      );
    }

    .mat-button.mat-primary {
      background-color: mat.get-color-from-palette($accent, lighter);
    }
  }

  .calendar-selected {
    color: mat.get-color-from-palette($accent, default);
    background-color: rgba(
      $color: mat.get-color-from-palette($accent, lighter-contrast),
      $alpha: 0.8
    );

    .mat-ripple-element {
      background-color: rgba(
        $color: mat.get-color-from-palette($accent, default),
        $alpha: 0.2
      );
    }
  }

  .calendar-blocked {
    color: mat.get-color-from-palette($primary, lighter-contrast);
    background-color: mat.get-color-from-palette($primary, default);
  }

  .calendar-tentative {
    color: mat.get-color-from-palette($primary, lighter-contrast);
    background: repeating-linear-gradient(
      45deg,
      mat.get-color-from-palette($primary, lighter),
      mat.get-color-from-palette($primary, lighter) 10px,
      mat.get-color-from-palette($primary, 300) 10px,
      mat.get-color-from-palette($primary, 300) 20px
    );
  }

  .gallery-image-label {
    color: rgba(
      $color: mat.get-color-from-palette($accent, default),
      $alpha: 1
    );
    background-color: rgba(
      $color: mat.get-color-from-palette($primary, darker),
      $alpha: 0.8
    );
  }

  .image-dialog-backdrop {
    background-color: rgba(
      $color: mat.get-color-from-palette($accent, default-contrast),
      $alpha: 0.8
    );
  }

  .image-dialog-panel {
    .mat-dialog-container {
      background: initial;
      padding: 0;
    }
  }

  // Tag manager trigger checks click ID and we only assign ID to button (not some interal Material giblets)
  .mat-button-wrapper {
    pointer-events: none;
  }

  .call-to-action {
    background: linear-gradient(
      270deg,
      mat.get-color-from-palette($primary, default),
      mat.get-color-from-palette($accent, default)
    );
    background-size: 400% 400%;
    background-blend-mode: color-burn;

    -webkit-animation: CallToAction 5s ease infinite;
    -moz-animation: CallToAction 5s ease infinite;
    animation: CallToAction 5s ease infinite;
  }

  @-webkit-keyframes CallToAction {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @-moz-keyframes CallToAction {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes CallToAction {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
